'use client';

import { Route } from 'next';
import { useLayoutEffect } from 'react';

import { usePathname, useRouter, useSearchParams } from 'next/navigation';

export const ScrollToTop = () => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useLayoutEffect(() => {
    if (searchParams?.get('scrollToTop') !== 'true') {
      return;
    }

    const search = new URLSearchParams(searchParams ?? {});
    search.delete('scrollToTop');

    router.replace(`${pathname}?${search.toString()}` as Route, { scroll: true });
  }, [pathname, searchParams, router]);

  return null;
};
